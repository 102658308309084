exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-for-account-js": () => import("./../../../src/pages/apply-for-account.js" /* webpackChunkName: "component---src-pages-apply-for-account-js" */),
  "component---src-pages-apply-for-demo-js": () => import("./../../../src/pages/apply-for-demo.js" /* webpackChunkName: "component---src-pages-apply-for-demo-js" */),
  "component---src-pages-avoid-costly-disclosures-js": () => import("./../../../src/pages/avoid-costly-disclosures.js" /* webpackChunkName: "component---src-pages-avoid-costly-disclosures-js" */),
  "component---src-pages-build-your-archive-js": () => import("./../../../src/pages/build-your-archive.js" /* webpackChunkName: "component---src-pages-build-your-archive-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-security-summary-js": () => import("./../../../src/pages/security-summary.js" /* webpackChunkName: "component---src-pages-security-summary-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-unlock-your-data-js": () => import("./../../../src/pages/unlock-your-data.js" /* webpackChunkName: "component---src-pages-unlock-your-data-js" */),
  "component---src-pages-what-to-protect-js": () => import("./../../../src/pages/what-to-protect.js" /* webpackChunkName: "component---src-pages-what-to-protect-js" */),
  "component---src-pages-why-choose-us-js": () => import("./../../../src/pages/why-choose-us.js" /* webpackChunkName: "component---src-pages-why-choose-us-js" */),
  "component---src-templates-markdown-page-js": () => import("./../../../src/templates/MarkdownPage.js" /* webpackChunkName: "component---src-templates-markdown-page-js" */)
}

